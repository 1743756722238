<template>
  <component
    :is="useV2 ? 'auth-container-v2' : 'auth-container'"
    :form-name="!modalTwoFaOpened ? $t('registration.title') : ''"
    class="auth-register"
    @submit="onFormSubmit"
  >
    <template slot="default">
      <div v-if="!modalTwoFaOpened" class="auth-register__form">
        <exc-input
          v-model="$v.email.$model"
          :field="$v.email"
          :optional-errors="{
            ...customErrors,
            maxLength: $t('validators.maxLength', { max: 255 }),
            ...mix_getServerError('email'),
          }"
          :label="$t('registration.eMail')"
          type="email"
          size="s"
          autocomplete="email"
          @input="mix_clearServerError('email')"
        />
        <password-input
          v-model="$v.password.$model"
          :label="$t('registration.password')"
          :optional-errors="{
            ...customErrors,
            minLength: $t('validators.minLength', { min: 8 }),
            maxLength: $t('validators.maxLength', { max: 20 }),
            ...mix_getServerError('password'),
          }"
          :field="$v.password"
          size="s"
          autocomplete="current-password"
          @input="mix_clearServerError('password')"
        />

        <password-input
          v-model="$v.password_confirmation.$model"
          :label="$t('registration.confirmation')"
          :optional-errors="{
            ...customErrors,
            sameAs: $t('validators.sameAs', {
              label: $t('registration.welcomePass'),
            }),
            ...mix_getServerError('password_confirmation'),
          }"
          :field="$v.password_confirmation"
          size="s"
          autocomplete="new-password"
          @input="mix_clearServerError('password_confirmation')"
        />

        <generate-password @generate="generatePassword" />
      </div>

      <div v-else>
        <confirmation
          :email="emailConfirmation"
          :email-code-resend-in="emailConfirmationCodeExpiresIn"
          :extended-data="loginData"
          :is-modal="false"
          :is-subscribe-me="isSubscribeMe"
          :email-address="email"
          @close="onCloseModal"
          @back="onCloseModal"
        />
      </div>
    </template>

    <template v-if="!modalTwoFaOpened" #btn>
      <exc-checkbox
        id="register-checkbox"
        v-model="$v.terms.$model"
        @input="mix_clearServerError('terms')"
      >
        <i18n
          path="registration.checkBox"
          class="auth-register__checkbox-label txt--color-04 txt--14px txt--height-18px txt--weight-400"
        >
          <template #termsOfServiceUrl>
            <exc-link
              :to="localePath('/terms-of-service')"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                class="txt--main txt--bold"
                v-text="$t('registration.termsOfService')"
              />
            </exc-link>
          </template>
          <template #privacyNoticeUrl>
            <exc-link
              :to="localePath('/privacy-notice')"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                class="txt--main txt--bold"
                v-text="$t('registration.privacyNotice')"
              />
            </exc-link>
          </template>
        </i18n>
      </exc-checkbox>

      <!-- <exc-checkbox
        id="subscription"
        v-model="isSubscribeMe"
        class="mt-26"
      >
        <span class="txt--color-04 txt--14px txt--height-18px txt--weight-400">
          {{ $t('registration.subscribeMe') }}
        </span>
      </exc-checkbox> -->

      <base-validation-error
        class="pl-32"
        :error="$v.terms.$error ? $t('registration.privacyPolicyRequired') : ''"
      />

      <exc-button
        size="s"
        full-width
        :loading="loading"
        :disabled="$v.$invalid"
      >
        <span>
          {{ $t('registration.createFreeAccount') }}
        </span>
      </exc-button>
    </template>

    <template v-if="!modalTwoFaOpened" slot="bottom">
      <div class="auth-register__have-an-account">
        <span
          class="txt--color-03 txt--12px txt--height-16px txt--weight-600 mr-6"
        >
          {{ $t('registration.haveAnAccount') }}
        </span>
        <nuxt-link
          :to="localePath('/login')"
          class="txt--main txt--12px txt--height-16px txt--weight-600"
        >
          {{ $t('registration.login') }}
        </nuxt-link>
      </div>
    </template>
  </component>
</template>

<script>
import CheckboxInputItemModel from '@/lib/data-models/inputs/CheckboxInputItemModel'
import AuthFormSubmit from '@/mixins/auth/AuthFormSubmit'
import AuthMixinCaptcha from '@/mixins/auth/AuthMixinCaptcha'
import AuthMixinRoutes from '@/mixins/auth/AuthMixinRoutes'
import { serverFormMixin } from '@/mixins/serverFormMixin'
import { getDefaultHeadData } from '@/lib/utils'
// import { passwordValidations, onlyLatinLettersOrNumbersAndChars } from '@/plugins/validators.ts'
import { AUTH_CONTAINER_V2, EMAIL } from '@/constants'
import {
  ExcButton,
  ExcCheckbox,
  ExcInput,
  TdxInput,
  ExcLink,
} from '@/components/common'
import { validationMixin } from 'vuelidate'
import { email, maxLength, required, sameAs } from 'vuelidate/lib/validators'
import { REFERRAL_CODE } from '~/constants'

const TEMP_ID_KEY = 'temp_user_id'

export default {
  name: 'Register',
  components: {
    ExcCheckbox,
    ExcInput,
    ExcButton,
    ExcLink,
    TdxInput,
    AuthContainer: () =>
      import(
        /* webpackMode: "eager" */ '~/components/containers/AuthContainer.vue'
      ),
    AuthContainerV2: () =>
      import(
        /* webpackMode: "eager" */ '@/components/containers/AuthContainerV2.vue'
      ),
    PasswordInput: () =>
      import(
        /* webpackMode: "eager" */ '@/components/ui-kit/text-input/PasswordInput.vue'
      ),
    PasswordInputWithKey: () =>
      import(
        /* webpackMode: "eager" */ '@/components/ui-kit/text-input/PasswordInputWithKey.vue'
      ),
    BaseValidationError: () =>
      import(
        /* webpackMode: "eager" */ '@/components/base/BaseValidationError'
      ),
    GeneratePassword: () =>
      import(
        /* webpackMode: "eager" */ '@/components/button/GeneratePassword.vue'
      ),
    Confirmation: () =>
      import(
        /* webpackMode: "eager" */ '@/components/confirmation/Confirmation.vue'
      ),
    InfoIcon: () =>
      import(/* webpackMode: "eager" */ 'assets/icons/info-icon.svg?inline'),
  },
  mixins: [
    AuthMixinRoutes,
    AuthMixinCaptcha,
    AuthFormSubmit,
    validationMixin,
    serverFormMixin,
  ],
  middleware: 'withoutAuthOnly',
  data () {
    return {
      useV2: AUTH_CONTAINER_V2,

      title: this.$t('auth.signUp'),
      loading: false,

      email: '',
      password: '',
      tempUserId: '',
      password_confirmation: '',
      terms: false,
      isSubscribeMe: false,

      modalTwoFaOpened: false,
      emailConfirmation: false,
      emailConfirmationCodeExpiresIn: null,
    }
  },
  head () {
    return getDefaultHeadData({
      title: this.$t('meta.register.title'),
      description: this.$t('meta.register.description'),
      locale: this.$i18n.locale,
    })
  },

  computed: {
    isBtnFullWidth () {
      if (!process.client) return
      return this.$mq.resize && this.$mq.below(991)
    },
    checkbox () {
      return new CheckboxInputItemModel(1, 'Terms', 1)
    },
    customErrors () {
      return {
        required: this.$t('validators.required'),
        email: this.$t('validators.email'),
        atLeastOneLowercase: this.$t('validators.atLeastOneLowercase'),
        atLeastOneUppercase: this.$t('validators.atLeastOneUppercase'),
        atLeastOneNumeric: this.$t('validators.atLeastOneNumeric'),
        atLeastOneSpecial: this.$t('validators.atLeastOneSpecial'),
        onlyLatinLettersOrNumbersAndChars: this.$t(
          'validators.onlyLatinLettersOrNumbersAndChars',
        ),
      }
    },
    loginData () {
      return {
        email: this.email,
        password: this.password,
        [TEMP_ID_KEY]: this.tempUserId,
      }
    },
  },

  mounted () {
    if (process.client) {
      if (localStorage.getItem(EMAIL)) {
        this.email = localStorage.getItem(EMAIL)
        localStorage.removeItem(EMAIL)
      }
    }
  },

  methods: {
    async submit () {
      this.loading = true
      const {
        email,
        password,
        password_confirmation,
        terms,
        mix_captchaData,
      } = this
      const referral_code = this.$cookies.get(REFERRAL_CODE)

      try {
        const { response } = await this.$api.register({
          email,
          password,
          password_confirmation,
          terms,
          ...(referral_code && { referral_code }),
          ...mix_captchaData,
        })

        if (referral_code) {
          this.$cookies.remove(REFERRAL_CODE)
        }

        this.emailConfirmationCodeExpiresIn = response.life_time
          ? parseFloat(response.life_time)
          : null
        this.tempUserId = response.temp_user_id
        this.emailConfirmation = true
        this.modalTwoFaOpened = true
      } catch ({ response }) {
        if (response?.data?.errors) {
          this.mix_setServerErrors(response?.data.errors)
        }
      } finally {
        this.loading = false
      }

      this.localePath('/login')
    },
    onCloseModal () {
      this.modalTwoFaOpened = false
      this.emailConfirmation = false
      this.emailConfirmationCodeExpiresIn = null
      this.tempUserId = null
    },
    generatePassword (generatedPass) {
      this.password = generatedPass
    },
  },

  validations () {
    return this.mix_validationsServerWrapper({
      email: {
        required,
        email,
        maxLength: maxLength(255),
      },
      password: {
        onlyLatinLettersOrNumbersAndChars: this.$validators
          .onlyLatinLettersOrNumbersAndChars,
        ...this.$validators.passwordValidations,
      },
      password_confirmation: {
        sameAs: sameAs('password'),
      },
      terms: {
        checked: v => !!v,
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.auth-register {
  display: flex;
  flex: 1;

  .exc-checkbox {
    padding-left: toRem(30px);
    line-height: toRem(18px);
  }

  &__have-an-account {
    display: flex;
  }
}

.tdx-base-input {
  padding: 0 35.5px 0 11.5px;
  line-height: 30px;
}

::v-deep {
  .exc-checkbox label::before {
    top: 10px !important;
  }
}
</style>
